/*jslint browser: true, nomen: true */
/*global $ */
'use strict';

function PageTimer() {
  var self = this;
  this.getLoadTime = function () {
    var now = new Date().getTime();
    // Get the performance object
    window.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
    var timing = performance.timing || {};

    var ms = now - timing.navigationStart;

    return Math.round(ms / 10)/100;
  };

  this.logToConsole = function () {
    $(window).on('load', function () {
      console && console.info && console.info("Loaded in " + self.getLoadTime() + 's');
    });
  };

  this.append = function (holder) {
    $(window).on('load', function () {
      holder.text(' | LT: ' + self.getLoadTime() + 's');
    });
  }
}


function StickyFooter ($wrap, $footer) {
  var $window = $(window);
  this.updateWrapperCSS = function () {
    var footerHeight = $footer.outerHeight();

    $wrap.css({
      marginBottom: -1 * footerHeight,
      paddingBottom: footerHeight
    });
  };

  this.bindOnResize = function () {
    $window.on('resize', this.updateWrapperCSS);

    return this;
  };

  this.update = function () {
    this.updateWrapperCSS();

    return this;
  }
}


/*
 Ensure all external links load as new window/tabs:
 */

var App;

function ExternalLinkHandler () {
  var self = this;
  var hostname = document.location.hostname;

  this.matchInternalLink = [new RegExp("^https?:\/\/(.*?)" + hostname)];

  this.addTargetAttribute = function (context) {
    context.find('a').filter('[href^="http://"], [href^="https://"]').each(function () {
      var anchor = $(this);
      var href = anchor.attr('href');
      var isInternalLink = false;

      for (var i = 0; i < self.matchInternalLink.length; i++) {
        var regex = self.matchInternalLink[i];
        if (href.match(regex)) {
          isInternalLink = true;
        }
      }

      if (!isInternalLink) {
        anchor.attr('target', '_blank').addClass('external-link');
      }
    });
  };

}

function UIBindings () {
  this.bindTooltips = function () {
    $('[data-toggle="tooltip"]').tooltip();
  };
  this.bindPopovers = function () {
    $('[data-toggle="popover"]').popover();
  };

  this.bindSlickCarousels = function () {
    $('[data-slick-carousel-default]').slick({
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true,
      autoplay: true,
      asNavFor: '.slider-nav'
    });

    $('.slider-nav').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: '.slider-for',
      dots: true,
      centerMode: true,
      focusOnSelect: true
    });

    $('[data-toggle="slick-nav"]').on('click', function (e) {
      e.preventDefault();
      // data-index="1"
      var index = $(this).data('index');
      $('[data-slick-carousel-default]').slick('slickGoTo', index);
    });
  };

  this.bindSharing = function () {
    $("[data-share-default]").each(function () {
      var shareHandler = new ShareHandler($(this));
      shareHandler.appendFacebook();
      shareHandler.appendTwitter();
      shareHandler.appendGoogle();
      //shareHandler.appendLinkedIn();
      //shareHandler.appendPinterest();
    });
  };

  this.bindMagnificpopup = function(){
    $('.gallery-image').magnificPopup({
         type: 'image',
         callbacks:
          {
              markupParse: function(template, values, item)
                  {
                      values.description = item.el.data('description');
                  }
          },
          gallery:{
            enabled:true
          },
          
          image:
              {
                  headerFit: true,
                  captionFit: true,
                  preserveHeaderAndCaptionWidth: false,
                  markup:
                  '<div class="mfp-figure">'+
                      '<figure>'+
                          '<header class="mfp-header">'+
                              '<div class="mfp-top-bar">'+
                                  '<div class="mfp-title"></div>'+
                                  '<div class="mfp-close"></div>'+
                                  '<div class="mfp-decoration"></div>'+
                              '</div>'+
                          '</header>'+
                          '<section class="mfp-content-container">'+
                              '<div class="mfp-img"></div>'+
                          '</section>'+
                          '<footer class="mfp-footer">'+
                              '<figcaption class="mfp-figcaption">'+
                                  '<div class="mfp-bottom-bar-container">'+
                                      '<div class="mfp-bottom-bar">'+
                                          '<div class="mfp-counter"></div>'+
                                          '<div class="mfp-description"></div>'+
                                          '<div class="mfp-decoration"></div>'+
                                      '</div>'+
                                  '</div>'+
                              '</figcaption>'+
                          '</footer>'+
                      '</figure>'+
                  '</div>',
              } 
    });
  };

  this.bindMasonary = function(){
    //  $('img').load(function(){
    //       $(".grid").masonry();
    //   });
    // $('.grid').masonry({
    //     itemSelector: '.grid-item',
    //     columnWidth: '.grid-sizer',
    //     gutter:5,
    //   });
  }

}

$(function () {

  $('.ui.menu .ui.dropdown').dropdown({
    on: 'hover'
  });

  (new StickyFooter($('#container'), $('#footer'))).update().bindOnResize();

  (new ExternalLinkHandler).addTargetAttribute($('body'));

  (new UIBindings).bindTooltips();

  (new UIBindings).bindPopovers();

  (new UIBindings).bindSlickCarousels();

  (new UIBindings).bindSharing();

  (new PageTimer).logToConsole();

  // (new UIBindings).bindMasonary();

  (new UIBindings).bindMagnificpopup();

   // gallery slidder
  $('.gallery-thumbnails').magnificPopup({

      delegate: 'a.gallery-items',
      type: 'image',
      tLoading: 'Loading image #%curr%...',
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        tCounter: '%curr% of %total%',
        preload: [0,2] // Will preload 0 - before current, and 1 after the current image
      },
      image: {
        //tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        titleSrc: function(item) {
          //return item.el.attr('title') + '<small>by Marsel Van Oosten</small>';
        }
      }
    });
});

//remove &#8203 from being injected into html
$( "#content" ).each(function() {
  $(this).html(jQuery(this).html().replace(/\u200B/g,''));
});


